<template>
  <div :class="contentWidth === 'boxed' ? 'container p-0' : null">
    <div class="position-absolute position-right-0">
      <b-navbar-nav class=" d-flex flex-row justify-content-end align-items-center">
        <!-- <span>{{ $t('login.chooseLanguage') }}</span> -->
        <div
          class="px-1 rounded badge-light"
          style="background: none; z-index: 1000;"
        >
          <Locale />
        </div>
      </b-navbar-nav>
    </div>
    <router-view />
  </div>
</template>

<script>
import {
  BNavbarNav,
} from 'bootstrap-vue'

import useAppConfig from '@core/app-config/useAppConfig'
import Locale from '@core/layouts/components/app-navbar/components/Locale.vue'

export default {
  components: {
    BNavbarNav,

    // eslint-disable-next-line vue/no-unused-components
    Locale,
  },
  setup() {
    const { contentWidth } = useAppConfig()
    return { contentWidth }
  },
}
</script>
