var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: _vm.contentWidth === 'boxed' ? 'container p-0' : null
  }, [_c('div', {
    staticClass: "position-absolute position-right-0"
  }, [_c('b-navbar-nav', {
    staticClass: " d-flex flex-row justify-content-end align-items-center"
  }, [_c('div', {
    staticClass: "px-1 rounded badge-light",
    staticStyle: {
      "background": "none",
      "z-index": "1000"
    }
  }, [_c('Locale')], 1)])], 1), _c('router-view')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }